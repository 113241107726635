* {
  font-family: "Cabin", sans-serif;
  margin: 0.5vh;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  font-size: 2rem;
}
h1 {
  font-size: 6vw;
}
h3 {
  font-size: 3vw;
  background-color: gold;
}
